import React, {useEffect, useState, useCallback} from 'react'
import {useParams, useNavigate} from "react-router-dom";
import {getCommonClassList,createFunZone,editFunZone,getFunZoneDetails,upload_file,fileUploadApiCall} from '../../api/apiHandler'
import $ from 'jquery';
import Alert from '../../components/Alert/Alert';
import Option from '../../components/Option/Option';
import Select from 'react-select';
import {uploadFunZoneImages,getMainBucketPath,getFunZoneBucketPath} from '../../AWS/MyS3'

export default function FunZoneCreate(props) {

   window.Buffer = window.Buffer || require("buffer").Buffer;
    let navigate = useNavigate();


    let isEditFirstTimeSetDataSchool=""    
    let isEditFirstTimeSetDataClassDiviosn=""

    let tempStudentDetails={}

    const category =  [
      { value: 'game', label: 'Game' },
      { value: 'cartoon', label: 'Cartoon' },
      { value: 'well_being', label: 'Well Being' },
      { value: 'meme', label: 'Meme' },
      { value: 'video', label: 'Video' },
      { value: 'coding', label: 'Coding' },
      { value: 'music', label: 'Music' }
    ] 

    const contentType =  [
      { value: 'link', label: 'Link' }
        ]

    const [studentDetails, setStudentDetails] = useState(null)

    const [classList, setClasslList] = useState([])
 
    const [selecteClassOption, setSelectClassOption] = useState(null);

    const [selectedCategory, setSelectedCategory] = useState(category[0]);
    const [selectedContentType, setSelectedContentType] = useState(contentType[0]);
    const [selectedCovorFile, setSelectedCovorFile] = useState(null);

    let id = useParams()

    const initalValue = {title: "",ar_title:"",tu_title:"", description: "", ar_description: "", tu_description: "", content:"",ar_content:"",tu_content:""}
    const [formValues, setFormValues] = useState(initalValue)

    useEffect(() => {
        console.log("selectedCovorFile", selectedCovorFile);
    },[selectedCovorFile])

    const [msg, setMsg] = useState(null);
    const showAlert = (msgDisplay, type) => {
        setMsg({
            msg: msgDisplay,
            type: type
        })
    }

    const handleCategoryChange=(data)=>{
      console.log(data)
      setSelectedCategory(data)
  }
  const handleContentTypeChange=(data)=>{
    console.log(data)
    setSelectedContentType(data)
}


    const handleClassChange=(data)=>{
        data = data.some(option => option.value === 0) ? classList : data;
        console.log("Selecting list", data); 
        setSelectClassOption(data)
    }


    const handleChange = (e) => {
        if (e.target.value.trim() == "") {
            e.target.value = e.target.value.trim()
        }
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    }

    const handleFileCoverInput = (e) => {
          props.isLoader(true);
          const file = e.target.files[0];
          const newFileName = new Date().getTime() + file.name;
          const content_type = file.type;
          const key = `cybersmarties/fun_zone/${newFileName}`;
          upload_file({key, content_type}).then(data => {
              if (data.data.code === 1) {
                  const signedUrl = data.data.data ? data.data.data : null;
                  const fileParameters = {
                      signedUrl: signedUrl,
                      file: file,
                      contentType: content_type,
                      onProgress: null
                  }
                  fileUploadApiCall(fileParameters).then(resultdata => {
                    setSelectedCovorFile(newFileName);
                      props.isLoader(false);
                  }).catch(err => {
                      props.isLoader(false);
                      console.log('handleFileCoverInput', err);
                  });
              }
          }).catch(err => {
              props.isLoader(false);
              console.log(err);
          });
  }

    const clickSubmitButton = (e) => {
        e.preventDefault();
        console.log(formValues)
        if (props.type == "e") {
            updateApiCall(formValues)
        } else {
            createApiCall(formValues)
        }
    }

    const createApiCall = (initValues) => {
        console.log("Create Api Call")
        props.isLoader(true)

        let classIdList=[]

        if(selecteClassOption!==null){
            if(selecteClassOption.length>0){
                selecteClassOption.forEach((item)=>{
                    classIdList.push(item.value)                     
                })
                }
        }

        let permanent_classes=classIdList.join(",")
        let userData = JSON.parse(localStorage.getItem("userData"));

        let apiData={"permanent_classes":permanent_classes,"title":initValues.title,"ar_title":initValues.ar_title,"tu_title":initValues.tu_title,"description":initValues.description,"ar_description":initValues.ar_description,"tu_description":initValues.tu_description,"content":initValues.content,"ar_content":initValues.ar_content,"tu_content":initValues.tu_content,"category":selectedCategory.value,"content_type":selectedContentType.value,"cover_image":(selectedCovorFile==null)? "":selectedCovorFile,"user_id":userData.id,"user_role":userData.role,"user_name":userData.username}

        console.log(apiData)

        createFunZone(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });        
    }

    const updateApiCall = (initValues) => {
        console.log("Update Api Call")
        props.isLoader(true)

        let classIdList=[]

        if(selecteClassOption!==null){
            if(selecteClassOption.length>0){
                selecteClassOption.forEach((item)=>{
                    classIdList.push(item.value)                     
                })
                }
        }

        let permanent_classes = classIdList.join(",")

        // let apiData={};
        // if (id.funId) apiData.funzone_id = id.funId;

        // let apiData={
        //     "funzone_id":id.funId,
        //     "permanent_classes":permanent_classes,
        //     "title":initValues.title,
        //     "ar_title":initValues.ar_title,
        //     "tu_title":initValues.tu_title,
        //     "description":initValues.description,
        //     "ar_description":initValues.ar_description,
        //     "tu_description":initValues.tu_description,
        //     "content":initValues.content,
        //     "ar_content":initValues.ar_content,
        //     "tu_content":initValues.tu_content,
        //     "category":selectedCategory.value,
        //     "content_type":selectedContentType.value,
        //     "cover_image":(selectedCovorFile==null)? "":selectedCovorFile
        // }

        let apiData = {};

        if (id.funId) apiData.funzone_id = id.funId;
        if (permanent_classes) apiData.permanent_classes = permanent_classes;
        if (initValues.title) apiData.title = initValues.title;
        if (initValues.ar_title) apiData.ar_title = initValues.ar_title;
        if (initValues.tu_title) apiData.tu_title = initValues.tu_title;
        if (initValues.description) apiData.description = initValues.description;
        if (initValues.ar_description) apiData.ar_description = initValues.ar_description;
        if (initValues.tu_description) apiData.tu_description = initValues.tu_description;
        if (initValues.content) apiData.content = initValues.content;
        if (initValues.ar_content) apiData.ar_content = initValues.ar_content;
        if (initValues.tu_content) apiData.tu_content = initValues.tu_content;
        if (selectedCategory?.value) apiData.category = selectedCategory.value;
        if (selectedContentType?.value) apiData.content_type = selectedContentType.value;
        if (selectedContentType?.value) apiData.cover_image = selectedCovorFile ? selectedCovorFile : "";

        console.log(apiData)

        editFunZone(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });
    }

    const geClassListApi = (selectListData)=>{
        getCommonClassList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc"}).then((resposnse)=>{
            props.isLoader(false)
            if(resposnse.data.code==1){
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    list.push({label:item.name,value:item.id})
                })
                setClasslList(list);

                var selectedList=[]
                list.forEach((item)=>{
                    selectListData.forEach((subItem)=>{
                        if(subItem.toString() === item.value.toString()){
                            selectedList.push(item)
                        }
                    })
                })

                console.log("Selected List",selectedList);
                setSelectClassOption(selectedList)
    
            }else{
                setClasslList([])
            }
        })
    }

    useEffect(() => {
        if (props.type == "e") {
            console.log("Edit")
            props.isLoader(true)
            getFunZoneDetails({"funzone_id":id.funId}).then((resposnse)=>{
                console.log(resposnse)
                if(resposnse.data.code==1){

                    let data= {title: resposnse.data.data.title,ar_title:resposnse.data.data.ar_title,tu_title:resposnse.data.data.tu_title, description: resposnse.data.data.description, ar_description: resposnse.data.data.ar_description, tu_description: resposnse.data.data.tu_description, content:resposnse.data.data.content,ar_content:resposnse.data.data.ar_content,tu_content:resposnse.data.data.tu_content} 
                    setFormValues(data);
                    setStudentDetails(resposnse.data.data)
                    tempStudentDetails=resposnse.data.data

                    let selectCategoryData =category.filter((item)=> {return item.value === resposnse.data.data.category})
                    setSelectedCategory(selectCategoryData[0])

                    let selectContentTypeData =contentType.filter((item)=> {return item.value === resposnse.data.data.content_type})
                    setSelectedContentType(selectContentTypeData[0])

                    let coverImage=resposnse.data.data.cover_image.replace(getMainBucketPath(),"").replace(getFunZoneBucketPath(),"")
                    // console.log("Cover Image",coverImage)
                    $('#schoolCover').next(".custom-file-label").html(coverImage);

                    if(!(coverImage ==="")){
                      $('#schoolCover').prop('required',false);
                    }

                    setSelectedCovorFile(coverImage)

                    geClassListApi(resposnse.data.data.permanent_classes.split(","))
                }else{
                    navigate(-1)
                }
          })   
        }else{
            props.isLoader(true)
            geClassListApi([])
        }
    }, []);

    $(document).ready(function() {
      $('input[type="file"]').on("change", function() {
        let filenames = [];
        let files = this.files;
        if (files.length > 1) {
          filenames.push("Total Files (" + files.length + ")");
        } else {
          for (let i in files) {
            if (files.hasOwnProperty(i)) {
              filenames.push(files[i].name);
            }
          }
        }
        $(this)
          .next(".custom-file-label")
          .html(filenames.join(","));
      });
    });

    const getSelectValueClassTeacher =()=>{
        let selectData=""
        if(selecteClassOption!=null){
            if(selecteClassOption.length>0){
                selecteClassOption.forEach((item) =>{
                selectData=selectData+item.value
                })
            }
        }
        return selectData
    }

    const getClasses = [
        {
            "label": "Select Class",
            "value": 0
        },
        {
            "label": "Select All",
            "value": '1,2,3,4,5,6,7,8,9,10'
        },
        {
            "label": 1,
            "value": 1
        },
        {
            "label": 2,
            "value": 2
        },
        {
            "label": 3,
            "value": 3
        },
        {
            "label": 4,
            "value": 4
        },
        {
            "label": 5,
            "value": 5
        },
        {
            "label": 6,
            "value": 6
        },
        {
            "label": 7,
            "value": 7
        },
        {
            "label": 8,
            "value": 8
        },
        {
            "label": 9,
            "value": 9
        },
        {
            "label": 10,
            "value": 10
        }
    ]

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row clearfix ">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card m-t-15">
                            <div className="header">
                                <h2>{(props.type == "e") ? "Edit Fun Zone" : "Create Fun Zone"}</h2>
                            </div>
                            <div className="body">
                                <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                    <label htmlFor="title">Title</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Title' className="form-control" id="title"
                                                   name="title" required value={formValues.title}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="ar_title">Title Arabic</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Title Arabic' className="form-control" id="ar_title"
                                                   name="ar_title" required value={formValues.ar_title}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="tu_title">Title Turkish</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Title Turkish' className="form-control" id="tu_title"
                                                   name="tu_title" required value={formValues.tu_title}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="description">Description</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Description' cols="30" rows="5"
                                                      className="form-control no-resize" id="description" name="description"
                                                      required value={formValues.description}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <label htmlFor="ar_description">Description Arabic</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Description Arabic' cols="30" rows="5"
                                                      className="form-control no-resize" id="ar_description" name="ar_description"
                                                      required value={formValues.ar_description}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <label htmlFor="tu_description">Description Turkish</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Description Turkish' cols="30" rows="5"
                                                      className="form-control no-resize" id="tu_description" name="tu_description"
                                                      required value={formValues.tu_description}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <label htmlFor="name_en">Select Category</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selectedCategory}
                                                    onChange={handleCategoryChange}
                                                    options={category}
                                                    name="selectCategory" 
                                                 />
                                      <input name="category" className='dropHideInput' value={ (selectedCategory == null)? "" : ""+selectedCategory.value  } id="category" type="text" required/>            
                                    </div>

                                    <label htmlFor="name_en">Select ContentType</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selectedContentType}
                                                    onChange={handleContentTypeChange}
                                                    options={contentType}
                                                    name="selectContentType" 
                                                 />
                                      <input name="contentType" className='dropHideInput' value={ (selectedContentType == null)? "" : ""+selectedContentType.value  } id="contentType" type="text" required/>            
                                    </div>
                                    <label htmlFor="content">Content (Embed)</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Content (Embed)' cols="30" rows="5"
                                                      className="form-control no-resize" id="content" name="content"
                                                      required value={formValues.content}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <label htmlFor="ar_content">Content Arabic (Embed)</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Content (Embed)' cols="30" rows="5"
                                                      className="form-control no-resize" id="ar_content" name="ar_content"
                                                      required value={formValues.ar_content}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <label htmlFor="tu_content">Content Turkish (Embed)</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea placeholder='Content (Embed)' cols="30" rows="5"
                                                      className="form-control no-resize" id="tu_content" name="tu_content"
                                                      required value={formValues.tu_content}
                                                      onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <label htmlFor="school_log">Upload Cover Image</label>
                                    <div className="form-group">
                                            <div className="custom-file">
                                                <input type="file" name="filesCover[]"  className="custom-file-input form-control" id="schoolCover" required onChange={handleFileCoverInput} accept="image/*"/>
                                                <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                            </div>
                                    </div>

                                    <label htmlFor="name_en">Select Class</label>
                                    <div className="form-group">
                                     <Select
                                                   isMulti
                                                   value={selecteClassOption}
                                                    onChange={handleClassChange}
                                                    options={getClasses}
                                                    name="selectClass" 
                                    />
                                    <input name="selectClass" className='dropHideInput' value={ (selecteClassOption == null)? "" : getSelectValueClassTeacher()  } id="selectClass" type="text" required/>            
                                    </div>
                                    <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}