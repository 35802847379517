import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import { getClassList, getDevisionList, createFriendShip } from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
import Select from 'react-select';
import $ from 'jquery';

export default function FriendShip(props) {

    let { schoolId } = useParams();

    const [friendShipType, setFriendShipType] = useState(null);
    const [ classList, setClassList] = useState(null);
    const [ divisionList, setDivisionList] = useState(null);
    const [selectSections, setSelectSections] = useState([]);

    let navigate = useNavigate();

    useEffect(() => {
        if (friendShipType && friendShipType.value === 'class') {
            getClassList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id": schoolId}).then((response) => {
                if(response.data.code && response.data.code==1){
                    let result = (response.data.data.list).map ( item => {
                        return { label: item.name, value: item.id }
                    });
                    setClassList(result);
                } else {
                    setClassList([]);
                }
            });
        }
        if (friendShipType && friendShipType.value === 'division') {
            getDevisionList({
                page: 1,
                sort_by: "id",
                limit: 1000,
                order_by: "asc",
                school_id: schoolId,
            }).then((resposnse) => {
                if (resposnse.data.code == 1) {
                    let result = (resposnse.data.data.list).map ( item => {
                        return { label: item.name, value: item.id }
                    });
                    setDivisionList(result);
                } else {
                    setDivisionList([]);
                }
            });
        }
    }, [friendShipType]);

    const friendShipTypeList = [
        {
            label: 'School',
            Value: 'school'
        },
        {
            label: 'Class',
            value: 'class'
        },
        {
            label: 'Division',
            value: 'division'
        }
    ]

    const handleFriendChange = (data) => {
        setDivisionList([]);
        setClassList([]);
        setSelectSections([]);
        if(friendShipType !==data){
            setFriendShipType(data)
        }
    }

    const createApiCall = async (e) => {
        e.preventDefault();
        if (friendShipType && selectSections) {
            let result = await createFriendShip({ friendship: friendShipType.value, section_ids: selectSections.map(obj => obj.value).join(','), school_id: schoolId });
            if (result.data.code === 1) {
                navigate(-1);
            }
        }
    }

    const handleSectionChange=(data)=>{
        data = data.some(option => option.value === 0) ? classList : data;
        console.log("Selecting list", data); 
        setSelectSections(data)
    }

    

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row clearfix ">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card m-t-15">
                            <div className="header">
                                <h2>Create Friendship</h2>
                            </div>
                            <div className="body">
                                <form id="form_validation" method="" onSubmit={() => { }} action="#" >
                                    <label htmlFor="name_en">Select Friendship Type</label>
                                    <div className="form-group">
                                        <Select
                                            value={friendShipType}
                                            onChange={handleFriendChange}
                                            options={friendShipTypeList}
                                            name="selectClassList"
                                        />
                                        <input name="classSelect" className='dropHideInput' value={friendShipType} id="classSelect" type="text" required />

                                    </div>
                                    {classList && classList.length > 0 ? <><label htmlFor="name_en">Select Classes</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <Select
                                                closeMenuOnSelect={false}
                                                value={selectSections}
                                                isMulti
                                                options={classList}
                                                onChange={handleSectionChange}
                                            />
                                        </div>
                                    </div></> : <></>}
                                    {divisionList && divisionList.length > 0 ?  <><label htmlFor="name_en">Select Divisions</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <Select
                                                closeMenuOnSelect={false}
                                                value={selectSections}
                                                isMulti
                                                options={divisionList}
                                                onChange={handleSectionChange}
                                            />
                                        </div>
                                    </div></> : <></>}
                                    <button className="btn btn-raised btn-primary waves-effect" type="submit" onClick={(e) => { createApiCall(e); return false;}} disabled={selectSections.length === 0}>CREATE FRIENDSHIP</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
