import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getAdminDetails,
  getAssignSchool,
  // getPermissions,
  getPermissionsList,
  setPermissions,
} from "../../api/apiHandler";
import Alert from "../../components/Alert/Alert";
import Select from "react-select";

export default function AdminDetails(props) {
  let navigate = useNavigate();

  let { userId } = useParams();

  const [adminDetails, setAdminDetails] = useState(null);
  const [myAssignList, setAssignList] = useState(null);
  const [adminRoles, setAdminRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);

  useEffect(() => {
    let mounted = true;
    props.isLoader(true);
    getPermissionsList({}).then((response) => {
      if (response.data.code == 1) {
        let data = response.data.data.map((item) =>
          Object.assign({ value: item?.id, label: item?.menu_page_title })
        );
        data = data.filter(item => item.value != 4)
        // console.log("Selected data are : ", response.data.data);
        setAdminRoles(data);
      }
    });
    getAdminDetails({ admin_id: userId }).then((resposnse) => {
      // console.log(resposnse);
      if (resposnse.data.code == 1) {
        let data = resposnse.data.data.permission.map((item) =>
          Object.assign({ value: item?.admin_menu_id, label: item?.menu_page_title })
        );
        setSelectedRole(data)
        setAdminDetails(resposnse.data.data);
      } else {
        navigate(-1);
      }
    });

    getAssignSchool({ admin_id: userId }).then((resposnse) => {
      if (resposnse.data.code == 1) {
        setAssignList(resposnse.data.data.list);
      } else {
        setAssignList([]);
      }
    });

    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (adminDetails) {

      if(adminDetails.role=="sub_admin"){
        var data = ""
        selectedRole.forEach((item, index) => {
          if (index == selectedRole.length - 1){
              data = data + item.value
          }else{ 
              data = data + item.value + ","
          }
        })
        
        if(data!=""){
          setPermissions({ permission: data, id: adminDetails?.id });
        }
      }
    
    }
  }, [selectedRole]);

  const getRole = (roleData) => {
    let role = "";
    if (roleData === "school_admin") {
      role = "School Admin";
    } else if (roleData === "monitoring_admin") {
      role = "Monitoring Admin";
    } else {
      role = "Sub Admin";
    }

    return role;
  };

  const getLanguage = (lanData) => {
    let language = "";
    if (lanData === "ar") {
      language = "Arabic";
    } else if (lanData === "tr") {
      language = "Turkish";
    } else {
      language = "English";
    }

    return language;
  };

  const getSchoolDetails = (listSchool)=>{
    var schoolList=[]

    listSchool.map((item)=>{
      schoolList.push(item.school_name)
    })

    return schoolList.join(", ")
  }

  if (adminDetails == null || myAssignList == null) return null;

  props.isLoader(false);

  return (
    <section className="content home">
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 m-t-15">
            <div className="card">
              <div className="header">
                <h2>Admin Details</h2>
              </div>
              <div className="body">
                <div className="row justify-content-center align-self-center">
                  <div className="col-xl-4 align-self-center m-b-15">
                    <img
                      className="img-fluid img-thumbnail"
                      src={adminDetails.profile_image}
                      alt={adminDetails.profile_image}
                    />
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="official"
                    aria-expanded="true"
                  >
                    <li className="list-group-item">
                      <b className="mr-2">ID : </b> {adminDetails.id}
                    </li>
                    <li className="list-group-item">
                      <b className="mr-2">Name : </b> {adminDetails.name}
                    </li>
                    <li className="list-group-item">
                      <b className="mr-2">Username : </b>{" "}
                      {adminDetails.username}
                    </li>
                    <li className="list-group-item">
                      <b className="mr-2">Email : </b> {adminDetails.email}
                    </li>
                    <li className="list-group-item">
                      <b className="mr-2">Phone Number : </b>{" "}
                      {adminDetails.country_code +
                        " " +
                        adminDetails.phone_number}
                    </li>
                    <li className="list-group-item">
                      <b className="mr-2">Role : </b>{" "}
                      {getRole(adminDetails.role)}
                    </li>
                    <li className="list-group-item">
                      <b className="mr-2">Language : </b>{" "}
                      {getLanguage(adminDetails.language)}
                    </li>

                    {adminDetails?.role == "sub_admin" ? 
                    <></>
                      :
                    <>
                    <li className="list-group-item">
                      <b className="mr-2">Assign School : </b>{" "}
                      {
                       (adminDetails?.show_all_school.toString()=="1") ?
                       <>
                          {"All"}
                       </>
                       :
                       <>
                          {getSchoolDetails(adminDetails.schools)}
                       </>
                      }
                    </li>
                    </>
                    }

                    <li className="list-group-item">
                      <b className="mr-2">Create Date : </b>{" "}
                      {adminDetails.created_at}
                    </li>
                    <li className="list-group-item">
                      <b className="mr-2">Status : </b>{" "}
                      {adminDetails.is_active == 1 ? "Active" : "Inactive"}
                    </li>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {adminDetails?.role == "sub_admin" &&<div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Roles</h2>
              </div>
              <Select
                isMulti
                value={selectedRole}
                onChange={(data) => setSelectedRole(data)}
                options={adminRoles}
                name="selectSchoolData"
              />
            </div>
          </div>}
        </div>
      </div>
    </section>
  );
}
