import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getAdminList,deleteAdmin,changeAdminStatus} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';
import { toBeChecked } from '@testing-library/jest-dom/dist/matchers';
import { renderToStaticMarkup } from 'react-dom/server'

export default function AdminListing(props) {

    const [myAdminList, setAdminList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getAdminList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                setAdminList(resposnse.data.data.list);
            }else{
                setAdminList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const redirectCreateAdmin = ()=>{
        let path ="/createAdmin"
        navigate(path)
    }
    const redirectCreateSubAdmin = () => {
        let path = "/createSubAdmin"
        navigate(path)
    }

    const getRole = (roleData)=>{
        let role=""
        if(roleData==="school_admin"){
            role="School Admin"
        }else if(roleData==="monitoring_admin"){
            role="Monitoring Admin"
        }else{
            role="Sub Admin"
        }

        return role
    }

    function deleteUserIdWise(userId){
        // console.log("Call Delete"+userId)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteAdmin({"admin_id":userId}).then((resposnse)=>{
                // console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#mangeUser').DataTable().row("#userId"+userId).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }

    const statusChangeListner = (position)=>{

    //    console.log(position) 

       props.isLoader(true)

    //    console.log("Call api change status")

       let apiData={}
       if(myAdminList[position].is_active===1){
            apiData={"admin_id":myAdminList[position].id,"status":0}        
       }
       else{
            apiData={"admin_id":myAdminList[position].id,"status":1}        
       }

    //    console.log(apiData)

       changeAdminStatus(apiData).then((resposnse)=>{
        // console.log(resposnse)
        props.isLoader(false)
        if(resposnse.data.code==1){
            callAfterApiChangeStatus(position,true)
        }else{
            callAfterApiChangeStatus(position,false)
        }
        })    
    }

    const callAfterApiChangeStatus = (position,status)=>{

        if(status){

            if(myAdminList[position].is_active===1){
                myAdminList[position].is_active=0       
               }else{
                myAdminList[position].is_active=1
                }
        }

        setAdminList(myAdminList)

        manageCheckStatus(position)

    }

    const manageCheckStatus = (position)=>{
        var item=myAdminList[position];
 
        // console.log(position) 
  
         var data =renderToStaticMarkup(renderStatusUiText(myAdminList[position]));
         $('#mangeUser').DataTable().row("#userId"+item.id).cell("#changeStatus"+item.id).data(data).draw(false);
  
     }
 
     const renderStatusUiText=(item)=>{
         return (
             item.is_active===1 ?
             <>
             <span class="badge badge-success">Active</span>
             </>
             :
             <>                                                
             <span class="badge badge-danger">Inactive</span>
             </>
 
         )
     }


    if (myAdminList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Manage  "/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateAdmin}>Create school & monitoring admin</button>
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" style={{marginRight: 10}} onClick={redirectCreateSubAdmin}>Create sub admin</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Language</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Language</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Status Action</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>

                            { 
                            myAdminList && myAdminList.map((item,index)=>{
                                    return(
                                        <tr id={"userId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.username}</td>
                                            <td>{item.email}</td>
                                            <td>{item.country_code+" "+item.phone_number}</td>
                                            <td>{item.language}</td>
                                            <td>{getRole(item.role)}</td>
                                            <td id={"changeStatus"+item.id} className="text-center" > 
                                             {
                                                item.is_active===1 ?
                                                <>
                                                <span class="badge badge-success">Active</span>
                                                </>
                                                :
                                                <>                                                
                                                <span class="badge badge-danger">Inactive</span>
                                                </>
                                             }
                                            </td>
                                            <td>
                                            <i onClick={()=>statusChangeListner(index)} className="zmdi zmdi-refresh-alt"> </i>
                                            </td>
                                            <td className="text-right">
                                            <div className='action-btn'>                        
                                            <Link to={`/adminDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                            <a onClick={()=> {deleteUserIdWise(item.id)}} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            <Link to={(item.role=="sub_admin") ?  `/editSubAdmin/${item.id}` :  `/editAdmin/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                            </div>    
                                            </td>
                                        </tr>

                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
//                                            <Link to={`/editAdmin/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
