import axios from "axios";
import { axiosClient } from "./apiClient";

export function signInAdmin(data){
    return axiosClient.post('/api/admin/admin/user_login', data);
}

export function getSchoolList(data){
    return axiosClient.post('/api/admin/school/get_school_list', JSON.stringify(data));
}

export function getSchooldDetails(data){
    return axiosClient.post('/api/admin/school/get_school_detail', data);
}

export function getClassList(data){
    return axiosClient.post('/api/admin/class/get_class_list', data);
}

export function getDevisionList(data){
    return axiosClient.post('/api/admin/division/get_division_list', data);
}

export function getUserList(data){    
    return axiosClient.post('/api/admin/user/get_user_list', data);
}

export function createClass(data){
    return axiosClient.post('/api/admin/class/add_class', data);
}

export function editClass(data){
    return axiosClient.post('/api/admin/class/update_class', data);
}

export function deleteClass(data){
    return axiosClient.post('/api/admin/class/delete_class', data);
}

export function getClassDetails(data){
    return axiosClient.post('/api/admin/class/get_class_detail', data);    
}

export function createDevision(data) {
    return axiosClient.post('/api/admin/division/add_division', data);    
}

export function editDevision(data) {
    return axiosClient.post('/api/admin/division/update_division', data);        
}

export function getDevisionDetails(data){
    return axiosClient.post('/api/admin/division/get_division_detail', data);    
}

export function deleteDevision(data){
    return axiosClient.post('/api/admin/division/delete_division', data);    
}


export function createSchool(data) {
    return axiosClient.post('/api/admin/school/add_school', data);    
}

export function editSchool(data) {
    return axiosClient.post('/api/admin/school/update_school', data);        
}


export function deleteSchool(data){
    return axiosClient.post('/api/admin/school/delete_school', data);    
}


export function getSchoolDetails(data){
    return axiosClient.post('/api/admin/class/get_class_detail', data);    
}

export function updateSchoolStatus(data){
    return axiosClient.post('/api/admin/school/update_school_status', data);    
}

export function getUserDetails(data){
    return axiosClient.post('/api/admin/user/get_user_detail', data);    
}

export function getDeshboardData(){
    return axiosClient.post('/api/admin/common/get_dashboard_counts'); 
}

export function createUser(data){
    return axiosClient.post('/api/admin/user/add_user',data); 
}

export function createBulkUser(data){
    return axiosClient.post('/api/admin/user/add_bulk_student',data); 
}

export function editUser(data){
    return axiosClient.post('/api/admin/user/update_user',data); 
}

export function deleteUser(data){
    return axiosClient.post('/api/admin/user/delete_user',data); 
}

export function updateUserStatus(data){
    return axiosClient.post('/api/admin/user/update_user_status',data); 
}

export function getAdminList(data){
    return axiosClient.post('/api/admin/admin/get_admin_list',data); 
}

export function createAdmin(data){
    return axiosClient.post('/api/admin/admin/add_admin',data); 
}

export function getAdminDetails(data){
    return axiosClient.post('/api/admin/admin/get_admin_detail',data); 
}

export function editAdmin(data){
    return axiosClient.post('/api/admin/admin/update_admin',data); 
}

export function deleteAdmin(data){
    return axiosClient.post('/api/admin/admin/delete_admin',data); 
}

export function changeAdminStatus(data){
    return axiosClient.post('/api/admin/admin/update_admin_status',data);     
}

export function getFunZoneList(data){
    return axiosClient.post('/api/admin/funzone/get_funzone_list',data); 
}

export function createFunZone(data){
    return axiosClient.post('/api/admin/funzone/add_funzone',data); 
}

export function editFunZone(data){
    return axiosClient.post('/api/admin/funzone/update_funzone',data); 
}

export function getFunZoneDetails(data){
    return axiosClient.post('/api/admin/funzone/get_funzone_detail',data); 
}

export function deleteFunZone(data){
    return axiosClient.post('/api/admin/funzone/delete_funzone',data); 
}

export function changeFunZoneStatus(data){
    return axiosClient.post('/api/admin/funzone/update_funzone_status',data);     
}

export function addBlockWord(data){
    return axiosClient.post('/api/admin/blocked_word/add_block_word',data)
}

export function blockWordList(data){
    return axiosClient.post('/api/admin/blocked_word/get_blocked_word_list',data)
}

export function blockWordUpdateStatus(data){
    return axiosClient.post('/api/admin/blocked_word/update_blocked_word_status',data)
}

export function blockWordApproveReject(data){
    return axiosClient.post('/api/admin/blocked_word/approve_reject_blocked_word',data)
}

export function deleteBlockWord(data){
    return axiosClient.post('/api/admin/blocked_word/delete_blocked_word',data)
}

export function getAvaterList(data){
    return axiosClient.post('/api/admin/avatar/get_avatar_list',data)
}


export function createAvatar(data){
    return axiosClient.post('/api/admin/avatar/add_avatar',data)
}

export function updateAvatar(data){
    return axiosClient.post('/api/admin/avatar/update_avatar',data)
}

export function getAvatarDetails(data){
    return axiosClient.post('/api/admin/avatar/get_avatar_detail',data)
}

export function updateAvatarStatus(data){
    return axiosClient.post('/api/admin/avatar/update_avatar_status',data)
}

export function deleteAvatar(data){
    return axiosClient.post('/api/admin/avatar/delete_avatar',data)
}

export function getAssignSchool(data){
    return axiosClient.post('/api/admin/admin/get_assigned_schools',data)
}

export function getCommonClassList(data){
    return axiosClient.post('/api/admin/common_class/get_class_list',data)
}

export function createCommonClass(data){
    return axiosClient.post('/api/admin/common_class/add_class',data)
}

export function editCommonClass(data){
    return axiosClient.post('/api/admin/common_class/update_class',data)
}

export function getCommonClassDetails(data){
    return axiosClient.post('/api/admin/common_class/get_class_detail',data)
}

export function getStaticPageDetails(data){
    return axiosClient.post('/api/admin/common/get_content_page_details',data)
}

export function updateStaticPageDetails(data){
    return axiosClient.post('/api/admin/common/update_content_page_details',data)
}

export function getFAQList(data){
    return axiosClient.post('/api/admin/faqs/get_faqs_list',data); 
}

export function createFAQ(data){
    return axiosClient.post('/api/admin/faqs/add_faqs',data); 
}

export function editFAQ(data){
    return axiosClient.post('/api/admin/faqs/update_faqs',data); 
}

export function getFAQDetails(data){
    return axiosClient.post('/api/admin/faqs/get_faqs_detail',data); 
}

export function deleteFAQ(data){
    return axiosClient.post('/api/admin/faqs/delete_faqs',data); 
}

export function changeFAQStatus(data){
    return axiosClient.post('/api/admin/faqs/update_faqs_status',data);     
}

export function getNotificationList(data){
    return axiosClient.post('/api/admin/notification/get_notification_list',data);     
}

export function createNotification(data){
    return axiosClient.post('/api/admin/notification/send_notification',data);     
}

export function getUserPerfromance(data){
    return axiosClient.post('/api/admin/user/getUserPerformance',data);     
}
export function changePassword(data){
    return axiosClient.post('/api/admin/admin/change_password', data);
}
export function getPermissions(data){
    return axiosClient.post('/api/admin/admin/getPermissionById', data);
}
export function setPermissions(data){
    return axiosClient.post('/api/admin/admin/permissionById', data);
}

export function getPermissionsList(data){
    return axiosClient.post('/api/admin/admin/menu_list', data);
}

export function getOnlineUsers(){
    return axiosClient.get('/api/admin/admin/getonlineusers');
}

export function getWBStatData(data) {
    return axiosClient.post('/api/admin/wellbeingstat/get_wb_stat_data', data);
}

export function importConversationWBData(data) {
    return axiosClient.post('/api/admin/wellbeingstat/import_conversation_wb_data', data);
}

export function upload_file(data){
    return axiosClient.post(`/api/admin/file_upload/signed_url`, data);
}

export function syncTablesData(data) {
    return axiosClient.post(`/api/admin/sync/sync_data`, data);
}

export function funzoneInvalidate(data) {
    return axiosClient.post(`/api/admin/sync/reset_funzone`, data);
}

export function createFriendShip(data) {
    return axiosClient.post(`/api/admin/friendship/create_friendship`, data);
}

export function friendShipList(data) {
    return axiosClient.post(`/api/admin/friendship/get_friendship_list`, data);
}

export const fileUploadApiCall = async (req) => {
    const {
        signedUrl,
        file,
        contentType,
        onProgress
    } = req;
    try {
        const result = await axios
            .put(signedUrl, file, {
                onUploadProgress: onProgress,
                headers: {
                    "Content-Type": contentType,
                },
            });
        return result;
    } catch (err) {
        console.log(err);
    }
}